import {
  ButtonGroup,
  CircularProgress,
  FormLabel,
  IconButton,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import api from "../../../services/api";
import GeneralErrors from "../general-errors";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import IntlCurrencyInput from "react-intl-currency-input";

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

registerLocale("pt-BR", ptBr);

const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  border: 0;
  border-bottom: solid #9e9e9e 1px;
  height: 1.1876em;
  font: inherit;
  font-size: 15px;
  padding: 10px 0;
`;

const CustomIntlCurrencyInput = styled(IntlCurrencyInput)`
  width: 100%;
  border: 0;
  border-bottom: solid #9e9e9e 1px;
  height: 1.1876em;
  font: inherit;
  font-size: 15px;
  padding: 10px 0;
`;

export default function ASTextField({
  workflow,
  name,
  data,
  extraValues,
  setData,
  label,
  multiline,
  required,
  TextFieldProps,
  readonly = false,
  dateTime = false,
  currency = false,
  mask,
  effectHandleConfirm,
  setEffectHandleConfirm,
  disabled,
  fullWidth,
  onChange,
  type="text",
  mirrorToField
}) {
  const [editing, setEditing] = React.useState(undefined);
  const [editingExtraValues, setEditingExtraValues] = React.useState(undefined);
  const [defaultExtraValues, setDefaultExtraValues] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(undefined);
  const [value, setValue] = useState((data[name]) ? (!dateTime ? data[name] : new Date(data[name])) : null);

  const handleChange = async (event) => {
    const value = event.target.value;
    doChange(value);
    onChange && onChange(event);
  };

  const doChange = async (value) => {
    setEditing(value);
    setValue(value);
  };

  const handleDateChange = async (value) => {
    setEditing(value);
    setValue(value);
    onChange && onChange(value);
  };

  const handleOnKeyDown = async (ev) => {
    if (ev.key !== 'Enter' || !isEditing()) return;

    if (!multiline) {
      handleConfirm();
      ev.preventDefault();
    } else if (ev.ctrlKey || ev.metaKey) {
      handleConfirm();
      ev.preventDefault();
    }
  };

  const handleConfirm = async () => {
    if (required && !editing && !editingExtraValues) {
      return setErrors('Campo obrigatório');
    }

    setLoading(true);
    setErrors(undefined);

    const extraValuesClone = Object.assign({}, extraValues);

    if (mirrorToField) {
      extraValuesClone[mirrorToField] = editing || null;
    }

    try {
      await api.put(`/workflow/${workflow}/${data.id}/value`, {
        field: name,
        extraValues: extraValuesClone,
        value: editing || null
      });

      const dataClone = Object.assign({}, data);
      dataClone[name] = editing || null;

      if (extraValuesClone) {
        Object.keys(extraValuesClone).map(key => {
          dataClone[key] = extraValuesClone[key];
        });
      }

      setData(dataClone);

      setEditing(undefined);
      setEditingExtraValues(undefined);
    } catch (e) {
      setErrors(e?.response?.data ?? 'Erro ao enviar os dados');
    }

    setLoading(false);
  };

  const isEditing = () => {
    return !loading && ((editing !== undefined && editing !== data[name]) || editingExtraValues !== undefined);
  };

  const handleChangeCurrency = (event, value, _maskedValue) => {
    event.preventDefault();
    doChange(value);
    onChange && onChange(event);
  };

  useEffect(() => {
    if (defaultExtraValues === undefined) {
      setDefaultExtraValues(extraValues);
    } else {
      setEditing(editing || data[name]);
      setEditingExtraValues(extraValues);
    }
  }, [extraValues]);

  useEffect(() => {
    if (!effectHandleConfirm) return;

    if (required && !editing && !editingExtraValues) {
      return setErrors('Campo obrigatório');
    }

    handleConfirm();
    setEffectHandleConfirm(false);
  }, [effectHandleConfirm]);

  return (
    <>
      {!dateTime ? (
        <>
          {!mask && !currency && (
            <TextField
              type={type}
              label={label}
              error={errors}
              multiline={multiline}
              rows={6}
              rowsMax={10}
              onChange={handleChange}
              onKeyDown={handleOnKeyDown}
              defaultValue={value}
              required={required}
              fullWidth={fullWidth}
              InputProps={{
                readOnly: readonly,
                disabled: disabled
              }}
              {...TextFieldProps}
            />
          )}

          {currency && (
            <div style={{width: '100%'}}>
              <FormLabel
                filled={true}
                required={required}
                style={{ fontSize: 13 }}
                error={errors}
                component="legend">{label}</FormLabel>

              <CustomIntlCurrencyInput
                currency="BRL"
                error={errors}
                config={currencyConfig}
                onChange={handleChangeCurrency}
                onKeyDown={handleOnKeyDown}
                required={required}
                defaultValue={value}
                readOnly={readonly}
                disabled={disabled}
                fullWidth={fullWidth}
              />
            </div>
          )}
        </>
      ) : (
        <div style={{ width: '100%' }}>
          <FormLabel
            filled={true}
            required={required}
            style={{ fontSize: 13 }}
            error={errors}
            component="legend">{label}</FormLabel>
          <CustomDatePicker
            selected={value}
            onChange={handleDateChange}
            onKeyDown={handleOnKeyDown}
            dateFormat="dd/MM/yyyy HH:mm"
            placeholderText="dd/mm/aaaa --:--"
            locale="pt-BR"
            showTimeSelect
            timeFormat="p"
            timeIntervals={15}
            disabled={disabled}
            fullWidth={fullWidth}
            isClearable={!disabled}
          />
        </div>
      )}
      {loading && <CircularProgress />}

      {errors && <GeneralErrors errors={errors} />}

      {!disabled && isEditing() && (
        <ButtonGroup color="primary">
          <IconButton color="primary" style={{ width: 30 }} onClick={handleConfirm}>
            <CheckCircleIcon />
          </IconButton>

          <IconButton color="default" style={{ width: 30 }} onClick={() => {
            setEditing(undefined);
            setEditingExtraValues(undefined);
          }}>
            <CancelIcon />
          </IconButton>
        </ButtonGroup>
      )
      }
    </>
  );
}
